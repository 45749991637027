import type { HTMLConverter } from '../types';
import { convertLexicalNodesToReactNode } from '../serializeLexical';
import { JSX } from 'react';

export const HeadingHTMLConverter: HTMLConverter<any> = {
  converter({ converters, node, parent, options }) {
    const childrenText = convertLexicalNodesToReactNode({
      converters,
      lexicalNodes: node.children,
      parent: {
        ...node,
        parent,
      },
      options,
    });
    const HeaderTag = `${node.tag}` as keyof JSX.IntrinsicElements;

    return (
      <HeaderTag
        className={`${node.tag} ${options?.headingClass}`}
        style={{ color: options?.primaryColor ?? undefined }}
      >
        {childrenText}
      </HeaderTag>
    );
  },
  nodeTypes: ['heading'],
};
