import type { HTMLConverter } from '../types';
import styles from './Converters.module.scss';

import { convertLexicalNodesToReactNode } from '../serializeLexical';

export const ListHTMLConverter: HTMLConverter<any> = {
  converter: ({ converters, node, parent, options }) => {
    const childrenText = convertLexicalNodesToReactNode({
      converters,
      lexicalNodes: node.children,
      parent: {
        ...node,
        parent,
      },
      options,
    });

    switch (node?.tag) {
      case 'ol':
        return <ol>{childrenText}</ol>;
      case 'ul':
        return <ul className={styles.bulletList}>{childrenText}</ul>;
      default:
        return <span>unsupported list type {node?.tag}</span>;
    }
  },
  nodeTypes: ['list'],
};

export const ListItemHTMLConverter: HTMLConverter<any> = {
  converter: ({ converters, node, parent, options }) => {
    const childrenText = convertLexicalNodesToReactNode({
      converters,
      lexicalNodes: node.children,
      parent: {
        ...node,
        parent,
      },
      options,
    });

    return <li value={node?.value}>{childrenText}</li>;
  },
  nodeTypes: ['listitem'],
};
