import { ICtaField } from '@/src/payload-types';
import React from 'react';
import styles from './SubComponents.module.scss';
import Button from '@/src/components/ui/Button';
import { LiaArrowRightSolid } from 'react-icons/lia';

const Cta: React.FC<ICtaField> = (props) => {
  const { title, type, theme, path, target, buttonIcon } = props;

  return (
    title &&
    target && (
      <Button
        inNewTab={target === 'newTab'}
        className={styles.ctaButton}
        buttonTheme={theme}
        $buttonType={type || 'secondary'}
        href={path || '/'}
      >
        {title}
        {buttonIcon === 'arrow' && <LiaArrowRightSolid />}
      </Button>
    )
  );
};

export default Cta;
