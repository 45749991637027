import type { HTMLConverter } from '../types';
import { SerializedBlockNode } from '@payloadcms/richtext-lexical';
import BlogImageBlock from './blocks/BlogImageBlock';
import { serializeLexical } from '../serializeLexical';
import Cta from '@/src/components/shared/blocks/sub-components/Cta';
import { ICtaField, IFormBlock, Media } from '@/src/payload-types';
import { SerializedEditorState } from 'lexical';
import FormBlock from '@/src/components/shared/blocks/form/FormBlock';

export const BlockHtmlConverter: HTMLConverter<SerializedBlockNode> = {
  converter({ node }) {
    switch (node.fields.blockType) {
      case 'imageBlock':
        return (
          // TODO: Real typescript support without `as` casting?
          <BlogImageBlock
            image={node?.fields?.image as Media}
            caption={node?.fields?.caption as string}
            blockType={'imageBlock'}
          />
        );
      case 'inlineAnchorId':
        return <div id={node.fields?.anchorId as string} />;

      case 'largeBody':
        return (
          <div className={'bodyLarge'}>{serializeLexical(node.fields.richText as SerializedEditorState)}</div>
        );
      case 'ctaBlock':
        return <Cta {...(node.fields.cta as ICtaField)} />;
      case 'formBlock':
        return <FormBlock {...(node.fields as unknown as IFormBlock)} />;
      default:
        return <span>Unknown block type {node.fields.blockType}</span>;
    }
  },
  nodeTypes: ['block'],
};
