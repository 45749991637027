import type { HTMLConverter, SerializedLexicalNodeWithParent } from './types';
import { defaultHTMLConverters } from './defaultConverters';
import { Fragment, ReactNode } from 'react';
import { SerializedEditorState } from 'lexical';
import type { SerializedLexicalNode } from 'lexical';

export interface LexicalOptions {
  primaryColor?: string | null;
  altTextColor?: string | null;
  headingClass?: string | null;
}

export const serializeLexical = (data?: SerializedEditorState, options?: LexicalOptions): ReactNode => {
  // return '';
  try {
    const converters: HTMLConverter[] = defaultHTMLConverters;

    if (data?.root?.children?.length && converters?.length > 0) {
      return convertLexicalNodesToReactNode({
        converters,
        lexicalNodes: data?.root?.children,
        parent: data?.root,
        options,
      });
    }

    return '';
  } catch (err) {
    console.error('error serializing lexical', err);
    return '';
  }
};

export function convertLexicalNodesToReactNode({
  converters,
  lexicalNodes,
  parent,
  options,
}: {
  converters: HTMLConverter[];
  lexicalNodes: SerializedLexicalNode[];
  parent: SerializedLexicalNodeWithParent;
  options?: LexicalOptions;
}): ReactNode {
  const unknownConverter = converters.find((converter) => converter.nodeTypes.includes('unknown'));

  const convertedNodes = lexicalNodes.map(
    (node, i): { lexicalNode: SerializedLexicalNode; reactNode: ReactNode } => {
      const converterForNode = converters.find((converter) => converter.nodeTypes.includes(node.type));
      if (!converterForNode) {
        if (unknownConverter) {
          return {
            lexicalNode: node,
            reactNode: unknownConverter.converter({
              childIndex: i,
              converters,
              node,
              parent,
              options,
            }),
          };
        }
        return { lexicalNode: node, reactNode: <span>unknown node</span> };
      }

      return {
        lexicalNode: node,
        reactNode: converterForNode.converter({
          childIndex: i,
          converters,
          node,
          parent,
          options,
        }),
      };
    },
  );
  return convertedNodes.map(({ lexicalNode, reactNode }, i) => {
    return <Fragment key={i}>{reactNode}</Fragment>;
  });
}
