'use client';
import { IFormBlock } from '@/src/payload-types';
import React, { FormEventHandler, startTransition, useActionState, useEffect, useRef, useState } from 'react';
import { handleSubmitForm } from './form-action';
import styles from './FormBlock.module.scss';
import Button from '@/src/components/ui/Button';
import ArrowRight from '@/src/assets/svg/arrow-right.svg';
import Image from 'next/image';
import { serializeLexical } from '@/src/payload/lexical/serializeLexical';
import { isValidEmail } from '@/src/payload/utils/client.utils';

const FormBlock = (props: IFormBlock) => {
  // @ts-ignore
  const [state, formAction, isPending] = useActionState<any>(handleSubmitForm, {
    email: '',
    status: '',
  });
  const { form } = props;

  const emailRef = useRef<null | HTMLInputElement>(null);

  const [isFormInvalid, setFormInvalid] = useState(true);

  const handleInputChange = () => {
    setFormInvalid(emailRef?.current?.value && isValidEmail(emailRef.current.value) ? false : true);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    // Create a FormData object from the form
    const formData = new FormData(e.target as HTMLFormElement);
    startTransition(() => {
      // @ts-ignore
      formAction(formData);
    });
  };

  const isComplete = state.status === 'success';

  if (form && typeof form !== 'string' && form?.id) {
    return (
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
      >
        {form.fields?.map((field, i) => {
          switch (field.blockType) {
            case 'email':
              return (
                <div
                  className={`${styles.inlineInputContainer}`}
                  style={{ '--field-width': field.width ? `${field.width}` : '100%' } as React.CSSProperties}
                  key={i}
                >
                  <input
                    type="email"
                    name="email"
                    aria-label="email"
                    placeholder="Your Email"
                    className={styles.input}
                    ref={emailRef}
                    onChange={handleInputChange}
                    disabled={isComplete || isPending}
                  />
                  <Button
                    $buttonType={'secondary'}
                    buttonTheme={'light'}
                    className={styles.inlineInputEndAdornment}
                    disabled={isFormInvalid || isComplete || isPending}
                  >
                    <Image src={ArrowRight} alt={'input-arrow-right'} />
                  </Button>
                </div>
              );
          }
          return null;
        })}
        <div className={styles.successMessage}>
          {form?.confirmationType === 'message' &&
            form?.confirmationMessage &&
            state?.status === 'success' &&
            serializeLexical(form?.confirmationMessage)}
        </div>
      </form>
    );
  }
  return null;
};

export default FormBlock;
