'use client';

export default function imageLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  if (src?.includes('.svg')) {
    return `/api/media/file/${src}`;
  }
  return `${process.env.NEXT_PUBLIC_IMAGE_OPTIMIZER_URL}${src}?width=${width}&quality=${quality || 75}&format=auto`;
}
