import type { HTMLConverter } from '../types';

import { convertLexicalNodesToReactNode } from '../serializeLexical';

export const ParagraphHTMLConverter: HTMLConverter<any> = {
  converter({ converters, node, parent, options }) {
    const childrenText = convertLexicalNodesToReactNode({
      converters,
      lexicalNodes: node.children,
      parent: {
        ...node,
        parent,
      },
      options,
    });

    return <p style={{ color: options?.primaryColor ?? undefined }}>{childrenText}</p>;
  },
  nodeTypes: ['paragraph'],
};
