export function isValidEmail(email: string) {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function percentToDecimal(percent: string) {
  // Remove the '%' sign if present and convert to a number
  let value = parseFloat(percent.toString().replace('%', ''));

  // Divide by 100 to get the decimal value
  return value / 100;
}

export function getPayloadUrl(path = '') {
  return `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000'}${path}`;
}
